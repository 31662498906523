import * as React from "react"
import { AdministradorFamiliarScreen } from "../components/administradorFamiliar/AdministradorFamiliarScreen"
import Layout from '../components/layout'


const administradorFamiliar = () => {
  return (
    <Layout>
      <div className="container-xl">
        <AdministradorFamiliarScreen  />
      </div>
    </Layout>
  )
}

export default administradorFamiliar
