import React from 'react'

export const AdministradorFamiliarScreen = () => {
  return (
    <div className='row'>
      <div className='col-12'>
        <div className='h3 text-light'>Administrador familiar</div>
      </div>
      <div className='col-12'>

      </div>
    </div>
  )
}
